const siteConfig = {
	userNavigation: [],
	navigation: [
		{
			name: 'Home',
			href: '/',
			current: true,
		},
		{
			name: 'Apps',
			href: '/apps',
			current: false,
		},
		{
			name: 'Make a Payment',
			href: '/payments',
			current: false,
		},
	],
};
export default siteConfig;
