import React from 'react';
import {
	RouterProvider,
	createBrowserRouter,
	redirect,
} from 'react-router-dom';
// import Song from './components/Song';
import Login from './components/Auth/LoginSignupForm';
import LandingPage from './components/Landing/LandingPage';
import { useUser } from './context/UserContext';
import MainLayout from './layout/MainLayout';

import Sites from './components/Admin/Sites';
import Apps from './components/Apps';
import PaymentPage from './components/Payment/PaymentPage';
import Auth from './utils/auth';

export default function Router() {
	const { user, isLoggedIn } = useUser();
	const routes = [
		{
			path: '/',
			element: <MainLayout />,

			children: [
				{ path: '/', element: <LandingPage /> },
				{
					path: 'login',
					element:
						user && isLoggedIn ? <LandingPage /> : <Login signup={false} />,
				},
				// { path: 'signup', element: <Login signup={true} /> },
				// { path: 'verify', element: <Verify /> },
			],
		},
		{
			path: '/',
			element: <MainLayout />,
			loader: async () => {
				const token = sessionStorage.getItem(Auth.ACCESS_TOKEN_KEY);
				if (token) {
					const { user } = await Auth.getUser(token);
					// if (user && !user.isVerified) return redirect('/verify');
					return null; // allow through access
				}
				return redirect('/login');
			},
			children: [
				{ path: 'payments', element: <PaymentPage /> },
				{ path: 'apps', element: <Apps /> },
			],
		},
		{
			path: '/admin',
			element: <MainLayout />,
			loader: async () => {
				const token = sessionStorage.getItem(Auth.ACCESS_TOKEN_KEY);
				if (token) {
					const { user } = await Auth.getUser(token);
					if (!user) return redirect('/login');
					if (user && !user.isVerified) return redirect('/verify');
					if (user && !user.isAdmin) return redirect('/');
					return null; // allow through access
				}
				return redirect('/login');
			},
			children: [{ path: 'sites', element: <Sites /> }],
		},
		{ '*': { element: <h1>Not Found</h1> } },
	];
	const router = createBrowserRouter([...routes]);
	return <RouterProvider router={router} />;
}
