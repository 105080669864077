import axios from 'axios';
import Auth from '../utils/auth';
let isProd = window.location.href.includes('keiserdev') ? true : false;

const client = axios.create({
	baseURL: isProd ? 'https://keiserdev.com/api' : 'http://localhost:5024/api',

	headers: {
		Authorization: `Bearer ${sessionStorage.getItem(Auth.ACCESS_TOKEN_KEY)}`,
	},
});
export default client;
