import React, { useEffect } from 'react';
import client from '../../api/client';
import Table from '../Global/Table';
export default function Sites() {
	const [sites, setSites] = React.useState([]);
	const fetchData = async () => {
		const { data } = await client.get('/sites');
		setSites(data.sites);
	};
	useEffect(() => {
		fetchData();
	}, []);
	return (
		<div>
			<h1>Sites</h1>
			<Table data={sites} />
		</div>
	);
}
