import React from 'react';
import TableDynamic from './Global/TableDynamic';
import client from '../api/client';
export default function Apps() {
	const [apps, setApps] = React.useState([]);
	const fetchData = async () => {
		try {
			const { data } = await client.get('/apps');
			setApps(data.apps);
		} catch (error) {
			return console.error(error);
		}
	};
	React.useEffect(() => {
		fetchData();
	}, []);
	return (
		<div className='mt-4'>
			<TableDynamic data={apps} />
		</div>
	);
}
